import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import HPPLayout from "../../components/layouts/layout-hpp"
import Seo from "../../components/seohp"

import EopCalloutCards from "../../components/shared/generic/eop-callout-cards"
import akbcares from "../../images/AkebiaCares-logo.png"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"

import CalloutIcon1 from "../../images/icons/icon-callout-hp-efficacy.png"
import CalloutIcon2 from "../../images/icons/icon-callout-hp-safety.png"

import tsatChartLG from "../../images/hp/pharmaco/hp-pharmaco-tsat-chart-LG.png"
import ferrChartLG from "../../images/hp/pharmaco/hp-pharmaco-ferritin-chart-LG.png"
import tsatChartSM from "../../images/hp/pharmaco/hp-pharmaco-tsat-chart-SM.png"
import ferrChartSM from "../../images/hp/pharmaco/hp-pharmaco-ferritin-chart-SM.png"

const anchorClick = event => {
    event.preventDefault()
    event.stopPropagation()

    const bounds = document
        .getElementById("trial-design")
        .getBoundingClientRect()
    let scrollPosition = window.pageYOffset + bounds.top

    // Adjust for the height of the sticky header
    let stickyHdr
    setTimeout(function () {
        if (window.matchMedia("(min-width: 768px)").matches) {
            stickyHdr = document
                .getElementById("site-navigation")
                .getBoundingClientRect().height
        } else {
            stickyHdr = document
                .getElementById("hpp-navigation")
                .getBoundingClientRect().height
        }
        scrollPosition = scrollPosition - stickyHdr
        window.scrollTo({ top: scrollPosition, behavior: "smooth" })
    }, 100)
}

const hppPharmacodynamics = ({ location }) => (
    <HPPLayout
        location={location}
        locationHostName={location.hostname}
        jobCode="PP-AUR-US-1407 (v9.0)"
        jobCodeDate="11/24"
        references={[
            <>
                AURYXIA<sup>®</sup> [Package Insert]. Cambridge, MA: Akebia
                Therapeutics, Inc.
            </>,
            <>
                Umanath K, Jalal DI, Greco BA, et al; for Collaborative Study
                Group. Ferric citrate reduces intravenous iron and
                erythropoiesis-stimulating agent use in ESRD.{" "}
                <em>J Am Soc Nephrol</em>. 2015;26(10):2578-2587.
            </>,
            <>Data on File 2, Akebia Therapeutics, Inc.</>,
            <>Data on File 1, Akebia Therapeutics, Inc.</>,
            <>
                National Kidney Foundation, K/DOQI clinical practice guidelines
                for bone metabolism and disease in chronic kidney disease.{" "}
                <em>Am J Kidney Dis</em>. 2003;42(4 Suppl 3):S1-S201.
                doi:10.1053/S0272-6386(03)00905-3
            </>,
            <>
                Umanath K, Sika M, Niecestro R, et al; for Collaborative Study
                Group. Rationale and study design of a three period, 58-week
                trial of ferric citrate as a phosphate binder in patients with
                ESRD on dialysis. <em>Hemodial Int.</em> 2013;17(1):67-74.
                doi:10.1111/j.1542-4758.2012.00711.x
            </>,
        ]}
    >
        <Helmet>
            <html lang="en" />
            <link
                rel="canonical"
                href="https://www.auryxiahcp.com/hyperphosphatemia/pharmacodynamics"
            />
            <body id="hpp-hcp-pharmaco" className="hpp" />
        </Helmet>

        <Seo
            title="Pharmacodynamics"
            description="Review safety and the pharmacodynamics for AURYXIA® (ferric citrate) tablets. Read about tolerability, including adverse reactions. Read Important Safety Information and Full Prescribing information on this page."
        />

        <section id="hero" className="center">
            <div className="flex-row">
                <div>
                    <h1>Safety: Pharmacodynamics in&nbsp;AURYXIA</h1>
                </div>
                <div>
                    <h2 className="hpp">
                        AURYXIA has been shown to increase iron parameters,
                        including TSAT and ferritin<sup>1</sup>
                    </h2>
                    <ul>
                        <li>
                            AURYXIA has a warning for iron overload which may
                            lead to excessive elevation in iron stores; serum
                            ferritin and TSAT should be assessed prior to
                            initiating and monitored while on therapy
                            <sup>1</sup>*
                            <ul>
                                <li>
                                    During the 52‐week active‐controlled period
                                    in the Phase III trial in which concomitant
                                    use of IV iron was permitted, gradual
                                    increases in iron parameters occurred over
                                    the first 3 to 6 months and then plateaued
                                    <sup>1,2</sup>
                                </li>
                                {/* <li>Gradual increases in iron parameters occurred over the first 3-6 months and then plateaued<sup>2</sup></li> */}
                            </ul>
                        </li>
                    </ul>
                    {/* <p className="footnote center">*TSAT=transferrin saturation.</p> */}
                </div>
            </div>
        </section>

        <section id="sidebyside" className="col-10 center">
            <div
                className="flex-row"
                data-sal="slide-up"
                data-sal-duration="600"
                data-sal-delay="200"
                data-sal-ease="ease-out-expo"
            >
                <div>
                    <h4 className="hpp">
                        Assess iron parameters prior to initiating AURYXIA as a
                        phosphate binder and monitor while on therapy
                        <sup>1</sup>
                    </h4>
                </div>
                <div>
                    <h4 className="hpp">AND</h4>
                </div>
                <div>
                    <h4 className="hpp">
                        In patients receiving IV iron, a reduction in dose or
                        discontinuation of IV iron therapy may be required
                        <sup>1</sup>
                    </h4>
                </div>
            </div>
        </section>

        <section id="chart1" className="col-10 center">
            <div
                className="flex-row"
                data-sal="slide-up"
                data-sal-duration="600"
                data-sal-delay="200"
                data-sal-ease="ease-out-expo"
            >
                <div>
                    <h3 className="hpp">
                        Mean TSAT levels for AURYXIA vs Active Control
                        <sup>1,3</sup>*
                    </h3>
                    <figure>
                        <figcaption>
                            <p className="circle auryxia">AURYXIA (n=252)</p>
                            <p className="circle other">
                                ACTIVE CONTROL (n=137)
                            </p>
                        </figcaption>
                        <img
                            src={tsatChartLG}
                            className="large-only"
                            alt="Mean TSAT levels for AURYXIA vs Active Control"
                        />
                        <img
                            src={tsatChartSM}
                            className="small-only"
                            alt="Mean TSAT levels for AURYXIA vs Active Control"
                        />
                    </figure>
                </div>
            </div>
            <div className="footnote-wrap">
                <p className="center">
                    Mean TSAT increased from 31.3% at baseline to 39.2% at Week
                    52 (~8%)
                </p>
                <p className="footnote center">
                    <span>*</span>Active Control=sevelamer carbonate and/or
                    calcium acetate.
                </p>
            </div>
        </section>

        <section id="chart2" className="flood-hp center">
            <div
                className="flex-row"
                data-sal="slide-up"
                data-sal-duration="600"
                data-sal-delay="200"
                data-sal-ease="ease-out-expo"
            >
                <div>
                    <h3 className="hpp">
                        Mean serum ferritin levels for AURYXIA vs Active Control
                        <sup>1,3</sup>*
                    </h3>
                    <figure>
                        <figcaption>
                            <p className="circle auryxia">AURYXIA (n=253)</p>
                            <p className="circle other">
                                ACTIVE CONTROL (n=137)
                            </p>
                        </figcaption>
                        <img
                            src={ferrChartLG}
                            className="large-only"
                            alt="Mean serum ferritin levels for AURYXIA vs Active Control"
                        />
                        <img
                            src={ferrChartSM}
                            className="small-only"
                            alt="Mean serum ferritin levels for AURYXIA vs Active Control"
                        />
                    </figure>
                </div>
            </div>

            <div className="footnote-wrap">
                <p>
                    Mean serum ferritin increased from 593 ng/mL at baseline to
                    895 ng/mL at Week 52 (302 ng/mL)
                </p>
                {/* <p className="footnote">*TSAT=transferrin saturation.</p> */}
                <p className="footnote">
                    <span>*</span>Active Control=sevelamer carbonate and/or
                    calcium acetate.
                </p>
            </div>
        </section>

        <section id="considerations" className="col-10">
            <div className="flex-row">
                <div>
                    <h2 className="hpp">
                        Considerations when evaluating iron parameters
                    </h2>
                </div>
                <div>
                    <ul>
                        <li>
                            Is the patient on concomitant IV iron that may
                            affect lab results?
                        </li>
                        <li>
                            If the patient is not on IV iron, when was the last
                            dose?
                            <ul>
                                <li>
                                    Have they been off IV iron for an extended
                                    period of time?
                                </li>
                            </ul>
                        </li>
                        <li>
                            Do you look at trends of TSAT and/or ferritin
                            increases when you stop IV iron?
                        </li>
                        <li>
                            Was there a recent/current inflammatory event that
                            impacted iron parameters?
                        </li>
                        <li>
                            Is the lab value being impacted by the timing of the
                            blood draw?
                        </li>
                    </ul>
                </div>
            </div>
        </section>

        <EopCalloutCards
            siteAudience="hpp"
            callOneClass={"highlight"}
            callOneContent={
                <>
                    <div>
                        <img src={CalloutIcon1} alt="" width="98" height="98" />
                        <h3>
                            See how AURYXIA helped patients reach their target
                            goals
                        </h3>
                        <p>
                            AURYXIA helped patients reach and stay in the range
                            of <span className="nowrap">3.5-5.5 mg/dL</span>{" "}
                            during a <span className="nowrap">56-week</span>{" "}
                            trial.<sup>1</sup>
                            <br />
                            Patients had a mean serum phosphorus level of{" "}
                            <span className="nowrap">7.41 mg/dL</span> at
                            baseline and{" "}
                            <span className="nowrap">4.88 mg/dL</span> at Week
                            56.<sup>4</sup>
                        </p>
                    </div>
                    <div>
                        <a
                            href="/hyperphosphatemia/ferric-citrate-efficacy/"
                            className="cta hpp"
                        >
                            <span>EXAMINE EFFICACY</span>
                        </a>
                        <p>
                            <span
                                className="gradient-link anchor-link hpp"
                                onClick={anchorClick}
                                onKeyDown={anchorClick}
                                role="button"
                                tabIndex={0}
                            >
                                See trial design{" "}
                                <KeyboardArrowDown viewBox="6 6 12 12" />
                            </span>
                        </p>
                    </div>
                </>
            }
            callTwoClass={"default"}
            callTwoLink={"/hyperphosphatemia/ferric-citrate-reactions"}
            callTwoContent={
                <>
                    <div>
                        <img src={CalloutIcon2} alt="" width="98" height="98" />
                        <h3>
                            Get to know the proven safety profile of AURYXIA
                        </h3>
                        <p>
                            Explore the product safety and tolerability profile
                            for AURYXIA in a{" "}
                            <span className="nowrap">52-week</span> trial.
                            <sup>1,6</sup>
                        </p>
                    </div>
                    <div>
                        <button className="cta hpp">
                            <span>EXPLORE SAFETY</span>
                        </button>
                        <p>&nbsp;</p>
                    </div>
                </>
            }
        />

        <section id="crosslink-slab" className="akb akb_moa_section">
            <div
                className="flex-row"
                data-sal="zoom-in"
                data-sal-duration="600"
                data-sal-delay="200"
                data-sal-ease="ease-out-expo"
            >
                <div>
                    <img src={akbcares} alt="AkebiaCares" height="81" />
                    <h3>
                        Your partner in helping patients access the medication
                        they need.
                    </h3>
                    <p className="boldTxt">
                        Questions?
                    </p>
                    <p className="med_width">
                        AkebiaCares is standing by to answer your call.
                    </p>
                    <ul className="info_ul">
                        <li>
                            <a href="tel:18334253242">
                                1&#xFEFF;-&#xFEFF;833&#xFEFF;-&#xFEFF;4AKEBIA&nbsp;(425&#xFEFF;-&#xFEFF;3242)
                            </a>
                        </li>
                        <li>Monday&nbsp;-&nbsp;Friday</li>
                        <li className="hours_li">
                            8<span>AM</span>&nbsp;‐&nbsp;6<span>PM</span>
                            &nbsp;EST
                        </li>
                    </ul>
                    {/* <p>AURYXIA is on formulary with all major Medicare Part D Plans and on formulary with the majority of the commercial plans.<sup>7,8</sup></p> */}
                    <a
                        href="https://www.akebiacareshcp.com/"
                        className="cta akb"
                    >
                        <span>FIND SUPPORT OPTIONS</span>
                    </a>
                </div>
            </div>
        </section>

        <section id="interested-slab" className="col-6 center">
            <div
                className="flex-row"
                data-sal="slide-up"
                data-sal-duration="600"
                data-sal-delay="200"
                data-sal-ease="ease-out-expo"
            >
                <div>
                    <h3>You may also be interested&nbsp;in: </h3>
                    <p>
                        <Link
                            to="/hyperphosphatemia/ferric-citrate-reactions/"
                            className="gradient-link hpp"
                        >
                            ADVERSE REACTIONS
                        </Link>
                    </p>
                </div>
            </div>
        </section>

        <section id="trial-design" className="flood-hp col-10">
            <div calss="flex-row">
                <div>
                    <p>
                        <strong>
                            Trial design<sup>1,6</sup>
                        </strong>
                    </p>
                    <p>
                        A multicenter, randomized, open‐label trial evaluated
                        the ability of AURYXIA to lower serum phosphorus in
                        patients with CKD on dialysis over 56 weeks. Eligible
                        patients had serum ferritin &#x003C;1000 ng/mL, serum
                        TSAT &#x003C;50%, and serum phosphorus ≥2.5 and ≤8.0
                        mg/dL at the screening visit. The safety and efficacy of
                        AURYXIA were studied in the 52‐week active‐controlled
                        period (AURYXIA n=292, Active Control n=149), then
                        AURYXIA patients were re‐randomized to either continue
                        AURYXIA treatment or receive placebo during the
                        placebo‐controlled period, weeks 52‐56 (AURYXIA n=96,
                        placebo n=96). The primary endpoint was the change in
                        serum phosphorus from baseline (Week 52) to Week 56
                        between AURYXIA and placebo. The key secondary endpoint
                        was the change in serum phosphorus from baseline (Week
                        0) to Week 52 between AURYXIA and Active Control.
                    </p>
                    {/* <p>A multicenter, randomized, open-label, Phase III trial evaluated the safety and efficacy of AURYXIA as a phosphate binder in controlling serum phosphorus levels in adult patients with CKD on hemodialysis and peritoneal dialysis over 56 weeks. Eligible patients were on dialysis for &#x2265;3 months before screening, were prescribed 3 to 18 pills/day of commercially available phosphate binder, and had serum ferritin &#x003C;1000&nbsp;ng/mL, serum TSAT &#x003C;50%, and serum phosphorus &#x2265;2.5 and &#x2264;8.0 mg/dL at the screening visit. Patients who were intolerant to calcium acetate and sevelamer carbonate were not included in the trial.</p>

                    <p>The safety and efficacy of AURYXIA was studied in the 52-week Active Control Period (AURYXIA n=292, Active Control n=149). At the final Active Control Period visit, AURYXIA patients were re-randomized to either continue AURYXIA treatment or receive placebo as part of the Placebo-Controlled Period (AURYXIA n=96, placebo n=96). The primary endpoint of the pivotal trial was the change in serum phosphorus from baseline (Week 52) to Week 56 between AURYXIA and placebo in the 4-week Placebo-Controlled Period.</p> */}

                    <p className="footnote">
                        CKD=chronic kidney disease; TSAT=transferrin saturation;
                        Active Control=sevelamer carbonate and/or calcium
                        acetate.
                    </p>
                </div>
            </div>
        </section>
    </HPPLayout>
)

export default hppPharmacodynamics
