import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import IDALayout from "../../components/layouts/layout-ida"
import Seo from "../../components/seo"

const openIsiTray = event => {
    event.preventDefault()
    setTimeout(function () {
        var psp = document.querySelector("#psp-panel")
        psp.setAttribute("data-state", "tallbutcollapse")
        document.body.classList.add("no-overflow")
    }, 300)
}

const SitemapPage = ({ location }) => (
    <IDALayout
        location={location}
        locationHostName={location.hostname}
        jobCode="PP-AUR-US-1408 (v5.0)"
        jobCodeDate="11/24"
    >
        <Helmet>
            <html lang="en" />
            <link
                rel="canonical"
                href="https://www.auryxiahcp.com/iron-deficiency-anemia/sitemap"
            />
            <body className="ida" id="ida-hcp-sitemap" />
        </Helmet>

        <Seo
            title="Site map"
            description="Site map information for AURYXIA iron deficiency anemia site. See Prescribing Information and Full Safety Information."
        />
        <section id="sitemap" className="flood-ida col-10 center">
            <div className="flex-row">
                <div>
                    <h1>SITE MAP</h1>

                    <div className="flex-row">
                        <div>
                            <ul>
                                <li>
                                    <Link
                                        className="ida"
                                        to="/iron-deficiency-anemia/"
                                    >
                                        Home
                                    </Link>
                                </li>
                            </ul>

                            <ul>
                                <li>
                                    <strong>About AURYXIA</strong>
                                    <ul>
                                        <li>
                                            <Link
                                                className="ida"
                                                to="/iron-deficiency-anemia/about-auryxia/mechanism-of-action/"
                                            >
                                                Mechanism of Action
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="ida"
                                                to="/iron-deficiency-anemia/about-auryxia/patient-profile/"
                                            >
                                                Patient Profile
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="ida"
                                                to="/iron-deficiency-anemia/about-auryxia/resources"
                                            >
                                                Resources
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <ul>
                                <li>
                                    <Link
                                        className="ida"
                                        to="/iron-deficiency-anemia/efficacy/"
                                    >
                                        Efficacy
                                    </Link>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <strong>Safety</strong>
                                    <ul>
                                        <li>
                                            <Link
                                                className="ida"
                                                to="/iron-deficiency-anemia/safety/adverse-reactions/"
                                            >
                                                Adverse Reactions
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="ida"
                                                to="/iron-deficiency-anemia/safety/pharmacodynamics/"
                                            >
                                                Pharmacodynamics
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <Link
                                        className="ida"
                                        to="/iron-deficiency-anemia/dosing/"
                                    >
                                        Dosing
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <Link
                                        className="ida"
                                        to="#isi"
                                        onClick={openIsiTray}
                                    >
                                        Important Safety Information
                                    </Link>
                                </li>
                                <li>
                                    <a
                                        className="ida"
                                        target="_blank"
                                        href="https://akebia.com/connect/"
                                    >
                                        Contact Us
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="ida"
                                        target="_blank"
                                        href="https://akebia.com/termsofuse/"
                                    >
                                        Terms &amp; Conditions
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="ida"
                                        target="_blank"
                                        href="https://akebia.com/privacy-policy/"
                                    >
                                        Privacy Policy
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="ida"
                                        target="_blank"
                                        href="https://akebia.com/"
                                    >
                                        Akebia.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </IDALayout>
)

export default SitemapPage
