import * as React from "react"

import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import Layout from "../components/layouts/layout"
import Seo from "../components/seo"
import akbcares from "../images/AkebiaCares-logo.png"

import auryBottle from "../images/heroes/hero-gateway-sm.png"

const IndexPage = ({ location }) => (
    <Layout
        location={location}
        locationHostName={location.hostname}
        references={[
            <>
                AURYXIA<sup>®</sup> [Package Insert]. Cambridge, MA: Akebia
                Therapeutics, Inc.
            </>,
        ]}
        jobCode="PP-AUR-US-1411 (v3.0)"
        jobCodeDate="11/2024"
    >
        <Helmet>
            <html lang="en" />
            <link rel="canonical" href="https://www.auryxiahcp.com/" />
            <body className="gateway-home" id="gateway-home" />
        </Helmet>
        <Seo
            title="Home"
            description="Choose the indication you are prescribing for and learn what AURYXIA can do for your patients. Read Important Safety Information and Prescribing Information on this page."
            author="Auryxia"
        />
        <section id="hero" className="center">
            <div className="flex-row">
                <div>
                    <h1>Reach for AURYXIA</h1>
                    <p>Get started by choosing an&nbsp;indication: </p>
                    <div className="flex-row">
                        <div>
                            <h3 className="hpp">Hyperphosphatemia (HP)</h3>
                            <p>
                                For the control of serum phosphorus levels in
                                adult patients with chronic kidney disease on
                                dialysis<sup>1</sup>
                            </p>
                            <Link
                                to="/hyperphosphatemia-treatment/"
                                className="cta hpp"
                            >
                                <span>EXPLORE MORE</span>
                            </Link>
                        </div>
                        <div>
                            <h3 className="ida">
                                Iron deficiency anemia (IDA)
                            </h3>
                            <p>
                                For the treatment of iron deficiency anemia in
                                adult patients with chronic kidney disease not
                                on dialysis<sup>1</sup>
                            </p>

                            <Link
                                to="/iron-deficiency-anemia"
                                className="cta ida"
                            >
                                <span>EXPLORE MORE</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="small-only center">
            <div>
                <div>
                    <img src={auryBottle} alt="" />
                </div>
            </div>
        </section>

        <section id="akebia-cares" className="center">
            <div
                className="flex-row"
                data-sal="zoom-in"
                data-sal-duration="600"
                data-sal-delay="200"
                data-sal-ease="ease-out-expo"
            >
                <div>
                    <img src={akbcares} alt="AkebiaCares" />

                    <h3 className="akb">
                        Your partner in helping patients access the medication
                        they need
                    </h3>
                    <p>
                        AkebiaCares supports your patients with information and
                        resources that can help people start and stay on
                        AURYXIA.
                    </p>

                    <a
                        href="https://www.akebiacareshcp.com/"
                        className="cta akb"
                    >
                        <span>FIND SUPPORT OPTIONS</span>
                    </a>
                </div>
            </div>
        </section>
    </Layout>
)

export default IndexPage
