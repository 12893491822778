import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import HPPLayout from "../../components/layouts/layout-hpp"
import Seo from "../../components/seohp"

import EopCalloutCards from "../../components/shared/generic/eop-callout-cards"
import akbcares from "../../images/AkebiaCares-logo.png"
import CalloutIcon1 from "../../images/icons/icon-callout-hp-efficacy.png"
import CalloutIcon2 from "../../images/icons/icon-callout-hp-dosing.png"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"

const anchorClick = event => {
    event.preventDefault()
    event.stopPropagation()

    const bounds = document
        .getElementById("trial-design")
        .getBoundingClientRect()
    let scrollPosition = window.pageYOffset + bounds.top

    // Adjust for the height of the sticky header
    let stickyHdr
    setTimeout(function () {
        if (window.matchMedia("(min-width: 768px)").matches) {
            stickyHdr = document
                .getElementById("site-navigation")
                .getBoundingClientRect().height
        } else {
            stickyHdr = document
                .getElementById("hpp-navigation")
                .getBoundingClientRect().height
        }
        scrollPosition = scrollPosition - stickyHdr
        window.scrollTo({ top: scrollPosition, behavior: "smooth" })
    }, 100)
}

const hppSafety = ({ location }) => (
    <HPPLayout
        location={location}
        locationHostName={location.hostname}
        jobCode="PP-AUR-US-1407 (v9.0)"
        jobCodeDate="11/24"
        references={[
            <>
                AURYXIA<sup>®</sup> [Package Insert]. Cambridge, MA: Akebia
                Therapeutics, Inc.
            </>,
            <>Data on File 4, Akebia Therapeutics, Inc.</>,
            <>Data on File 11, Akebia Therapeutics Inc.</>,
            <>Data on File 10, Akebia Therapeutics Inc.</>,
            <>Data on File 6, Akebia Therapeutics Inc.</>,
            <>Data on File 1, Akebia Therapeutics Inc.</>,
            <>
                National Kidney Foundation, K/DOQI clinical practice guidelines
                for bone metabolism and disease in chronic kidney disease.{" "}
                <em>Am J Kidney Dis</em>. 2003;42(4 Suppl 3):S1-S201.
                doi:10.1053/S0272-6386(03)00905-3
            </>,
            <>
                Umanath K, Sika M, Niecestro R, et al; Collaborative Study
                Group. Rationale and study design of a three period, 58-week
                trial of ferric citrate as a phosphate binder in patients with
                ESRD on dialysis. <em>Hemodial Int.</em> 2013;17(1):67-74.
                doi:10.1111/j.1542-4758.2012.00711.x
            </>,
        ]}
    >
        <Helmet>
            <html lang="en" />
            <link
                rel="canonical"
                href="https://www.auryxiahcp.com/hyperphosphatemia/ferric-citrate-reactions"
            />
            <body id="hpp-hcp-safety" className="hpp" />
        </Helmet>

        <Seo
            title="Safety &amp; Tolerability"
            description="Explore the safety and tolerability profile for AURYXIA® (ferric citrate) to see if it is right for your Patients. Read Important Safety Information and Full Prescribing information on this page."
        />

        <section id="hero">
            <div className="flex-row">
                <div>
                    <h1>AURYXIA has a proven safety&nbsp;profile</h1>
                    <p>
                        Explore the safety and tolerability profile for AURYXIA
                        to see if it is right for your adult&nbsp;patients.
                        <sup>1</sup>
                    </p>
                </div>
            </div>
        </section>

        <section id="adverse-events" className="flood-hp col-10 center">
            <div
                data-sal="slide-up"
                data-sal-duration="600"
                data-sal-delay="200"
                data-sal-ease="ease-out-expo"
            >
                <div>
                    <h2 className="hpp">
                        A pooled safety analysis that included a 52&#8209;week pivotal
                        study<sup>1</sup>
                    </h2>

                    <div className="table-wrap">
                        <p>
                            The most common adverse reactions reported with
                            AURYXIA were<sup>1</sup>:
                        </p>

                        <table className="table adverse-events">
                            <thead>
                                <tr>
                                    <th>
                                        <strong>
                                            Adverse reactions in &#x003E;5% of
                                            patients
                                        </strong>
                                    </th>
                                    <th>
                                        <strong>
                                            AURYXIA
                                            <br />
                                            N=557
                                        </strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Diarrhea</td>
                                    <td>21%</td>
                                </tr>
                                <tr>
                                    <td>Discolored feces</td>
                                    <td>19%</td>
                                </tr>
                                <tr>
                                    <td>Nausea</td>
                                    <td>11%</td>
                                </tr>
                                <tr>
                                    <td>Constipation</td>
                                    <td>8%</td>
                                </tr>
                                <tr>
                                    <td>Vomiting</td>
                                    <td>7%</td>
                                </tr>
                                <tr>
                                    <td>Cough</td>
                                    <td>6%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p className="center">
                        In a pooled safety analysis of the 52-week pivotal study
                        and 3 short-term trials (N=557), the majority of
                        diarrhea cases (56%) resolved within 2 weeks from onset
                        <sup>1-3</sup>
                    </p>
                </div>
            </div>
        </section>

        <section id="safety" className="col-10 center">
            <div
                data-sal="slide-up"
                data-sal-duration="600"
                data-sal-delay="200"
                data-sal-ease="ease-out-expo"
            >
                <div>
                    <h2 className="hpp">
                        Safety and tolerability profile evaluated in a{" "}
                        <span className="nowrap">52-week</span> trial
                        <sup>1</sup>
                    </h2>
                    <p>
                        41.9% of patients on AURYXIA experienced an SAE compared
                        to 49.7% on Active Control<sup>5</sup>
                    </p>

                    <div className="table-wrap">
                        <p>
                            No individual SAEs were observed in more than 5% of
                            patients treated with AURYXIA
                        </p>
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        SAEs occurring in &#x2265;10% of
                                        patients taking AURYXIA vs Active
                                        Control by system organ class
                                        <sup>3,5</sup>
                                    </th>
                                    <th>
                                        AURYXIA
                                        <br />
                                        N=289
                                    </th>
                                    <th>
                                        ACTIVE CONTROL
                                        <br />
                                        N=149
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Gastrointestinal disorders</td>
                                    <td>7.6%</td>
                                    <td>12.8%</td>
                                </tr>
                                <tr>
                                    <td>Cardiac disorders</td>
                                    <td>7.6%</td>
                                    <td>11.4%</td>
                                </tr>
                                <tr>
                                    <td>Infection and infestations</td>
                                    <td>13.8%</td>
                                    <td>19.5%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p className="footnote center">
                        SAE=serious adverse event; Active Control=sevelamer
                        carbonate and/or calcium acetate.
                    </p>
                </div>
            </div>
        </section>

        <EopCalloutCards
            siteAudience="hpp"
            callOneClass={"highlight"}
            callOneContent={
                <>
                    <div>
                        <img src={CalloutIcon1} alt="" width="98" height="98" />
                        <h3>
                            See how AURYXIA helped patients reach their target
                            goals
                        </h3>
                        <p>
                            AURYXIA helped patients reach and stay in the range
                            of <span className="nowrap">3.5-5.5 mg/dL</span>{" "}
                            during a <span className="nowrap">56-week</span>{" "}
                            trial.<sup>1</sup>
                            <br />
                            Patients had a mean serum phosphorus level of 7.41
                            mg/dL at baseline and{" "}
                            <span className="nowrap">4.88 mg/dL</span> at Week
                            56.<sup>6</sup>
                        </p>
                    </div>
                    <div>
                        <a
                            href="/hyperphosphatemia/ferric-citrate-efficacy/"
                            className="cta hpp"
                        >
                            <span>EXAMINE EFFICACY</span>
                        </a>
                        <p>
                            <span
                                className="gradient-link anchor-link hpp"
                                onClick={anchorClick}
                                onKeyDown={anchorClick}
                                role="button"
                                tabIndex={0}
                            >
                                See trial design{" "}
                                <KeyboardArrowDown viewBox="6 6 12 12" />
                            </span>
                        </p>
                    </div>
                </>
            }
            callTwoClass={"default"}
            callTwoLink={"/hyperphosphatemia/dosing/"}
            callTwoContent={
                <>
                    <div>
                        <img src={CalloutIcon2} alt="" width="98" height="98" />
                        <h3>Ready to prescribe?</h3>
                        <p>
                            The first step to achieve the proven efficacy of
                            AURYXIA is to start with the recommended dose. Find
                            out how to dose and titrate AURYXIA.
                        </p>
                    </div>
                    <div>
                        <button className="cta hpp">
                            <span>GET DOSING INFORMATION</span>
                        </button>
                        <p>&nbsp;</p>
                    </div>
                </>
            }
        />

        <section id="crosslink-slab" className="akb akb_moa_section">
            <div
                className="flex-row"
                data-sal="zoom-in"
                data-sal-duration="600"
                data-sal-delay="200"
                data-sal-ease="ease-out-expo"
            >
                <div>
                    <img src={akbcares} alt="AkebiaCares" height="81" />
                    <h3>
                        Your partner in helping patients access the medication
                        they need.
                    </h3>
                    <p className="boldTxt">
                        Questions?
                    </p>
                    <p className="med_width">
                        AkebiaCares is standing by to answer your call.
                    </p>
                    <ul className="info_ul">
                        <li>
                            <a href="tel:18334253242">
                                1&#xFEFF;-&#xFEFF;833&#xFEFF;-&#xFEFF;4AKEBIA&nbsp;(425&#xFEFF;-&#xFEFF;3242)
                            </a>
                        </li>
                        <li>Monday&nbsp;-&nbsp;Friday</li>
                        <li className="hours_li">
                            8<span>AM</span>&nbsp;‐&nbsp;6<span>PM</span>
                            &nbsp;EST
                        </li>
                    </ul>
                    {/* <p>AURYXIA is on formulary with all major Medicare Part D Plans and on formulary with the majority of the commercial plans.<sup>8,9</sup></p> */}
                    <a
                        href="https://www.akebiacareshcp.com/"
                        className="cta akb"
                    >
                        <span>FIND SUPPORT OPTIONS</span>
                    </a>
                </div>
            </div>
        </section>

        <section id="interested-slab" className="col-6 center">
            <div
                className="flex-row"
                data-sal="slide-up"
                data-sal-duration="600"
                data-sal-delay="200"
                data-sal-ease="ease-out-expo"
            >
                <div>
                    <h3>You may also be interested&nbsp;in: </h3>
                    <p>
                        <Link
                            to="/hyperphosphatemia/pharmacodynamics/"
                            className="gradient-link hpp"
                        >
                            SAFETY: PHARMACODYNAMICS
                        </Link>
                    </p>
                </div>
            </div>
        </section>

        <section id="trial-design" className="flood-hp col-10">
            <div calss="flex-row">
                <div>
                    <p>
                        <strong>
                            Trial design<sup>1,8</sup>
                        </strong>
                    </p>
                    <p>
                        A multicenter, randomized, open‐label trial evaluated
                        the ability of AURYXIA to lower serum phosphorus in
                        patients with CKD on dialysis over 56 weeks. Eligible
                        patients had serum ferritin &#x003C;1000 ng/mL, serum
                        TSAT &#x003C;50%, and serum phosphorus ≥2.5 and ≤8.0
                        mg/dL at the screening visit. The safety and efficacy of
                        AURYXIA were studied in the 52‐week active‐controlled
                        period (AURYXIA n=292, Active Control n=149), then
                        AURYXIA patients were re‐randomized to either continue
                        AURYXIA treatment or receive placebo during the
                        placebo‐controlled period, weeks 52‐56 (AURYXIA n=96,
                        placebo n=96). The primary endpoint was the change in
                        serum phosphorus from baseline (Week 52) to Week 56
                        between AURYXIA and placebo. The key secondary endpoint
                        was the change in serum phosphorus from baseline (Week
                        0) to Week 52 between AURYXIA and Active Control.
                    </p>
                    {/* <p>A multicenter, randomized, open-label, Phase III trial evaluated the safety and efficacy of AURYXIA as a phosphate binder in controlling serum phosphorus levels in adult patients with CKD on hemodialysis and peritoneal dialysis over 56 weeks. Eligible patients were on dialysis for &#x2265;3 months before screening, were prescribed 3 to 18 pills/day of commercially available phosphate binder, and had serum ferritin &#x003C;1000 ng/mL, serum TSAT &#x003C;50%, and serum phosphorus &#x2265;2.5 and &#x2264;8.0 mg/dL at the screening visit. Patients who were intolerant to calcium acetate and sevelamer carbonate were not included in the trial.</p>
                    <p>The safety and efficacy of AURYXIA was studied in the 52-week Active Control Period (AURYXIA n=292, Active Control n=149). At the final Active Control Period visit, AURYXIA patients were re-randomized to either continue AURYXIA treatment or receive placebo as part of the Placebo-Controlled Period (AURYXIA n=96, placebo n=96). The primary endpoint of the pivotal trial was the change in serum phosphorus from baseline (Week 52) to Week 56 between AURYXIA and placebo in the 4-week Placebo-Controlled Period.</p> */}

                    <p className="footnote">
                        CKD=chronic kidney disease; TSAT=transferrin saturation;
                        Active Control=sevelamer carbonate and/or calcium
                        acetate.
                    </p>
                </div>
            </div>
        </section>
    </HPPLayout>
)

export default hppSafety
