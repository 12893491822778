import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layouts/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
    <Layout jobCode="PP-AUR-US-1411 (v2.0)" jobCodeDate="11/2024">
        <Helmet>
            <html lang="en" />
            <title>404: Page not found</title>
            <body className="" id="landing-404" />
        </Helmet>
        <Seo
            title="404"
            description="Official AURYXIA website for US Healthcare Professionals. Find all the information you need about FDA-approved AURYXIA® (ferric citrate) tablets."
        />
        <section id="hero" className="flood-hp col-6 center">
            <div className="flex-row">
                <div>
                    <h1>404 Error</h1>
                    <h2 className="hpp">Looking for AURYXIA?</h2>

                    <p>
                        404 error. Page not found. Go to the AURYXIA home page
                        and choose an indication.
                    </p>
                    <Link to="/" className="cta hpp">
                        <span>HOME</span>
                    </Link>
                </div>
            </div>
        </section>
    </Layout>
)

export default NotFoundPage
