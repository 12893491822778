import * as React from "react"
import { Helmet } from "react-helmet"

import HPPLayout from "../../components/layouts/layout-hpp"
import Seo from "../../components/seohp"

import EopCalloutCards from "../../components/shared/generic/eop-callout-cards"
import ImgMediaCard from "../../components/shared/generic/media-card"

import akbcares from "../../images/AkebiaCares-logo.png"
import CalloutIcon2 from "../../images/icons/icon-callout-hp-safety.png"

import iconMeals from "../../images/hp/dosing/ic_a1.png"
import iconTablets from "../../images/hp/dosing/ic_a2.png"
import iconCalendar from "../../images/hp/dosing/ic_a3.png"
import iconMaxDose from "../../images/hp/dosing/ic-a4.png"

import iconTitrationPills from "../../images/hp/dosing/ic_c2.png"
import iconTitrationCalendar from "../../images/hp/dosing/ic_b3.png"

const DosingPage = ({ location }) => (
    <HPPLayout
        location={location}
        locationHostName={location.hostname}
        jobCode="PP-AUR-US-1407 (v9.0)"
        jobCodeDate="11/24"
        references={[
            <>
                AURYXIA<sup>®</sup> [Package Insert]. Cambridge, MA: Akebia
                Therapeutics, Inc.
            </>,
        ]}
    >
        <Helmet>
            <html lang="en" />
            <link
                rel="canonical"
                href="https://www.auryxiahcp.com/hyperphosphatemia/dosing"
            />
            <body className="hyperphosphatemia" id="hpp-hcp-dosing" />
        </Helmet>

        <Seo
            title="Dosing"
            description="Learn about Dosing and Titration Guidelines for AURYXIA® (ferric citrate) for your Patients. Read Important Safety Information and Full Prescribing information on this page."
        />

        <section id="hero" className="center">
            <div className="flex-row">
                <div>
                    <h1>Ready to get a patient started on&nbsp;AURYXIA?</h1>
                    <p>Learn about dosing and titration.</p>
                    <p>
                        <strong>
                            AURYXIA is uniquely formulated for your patients:
                        </strong>
                    </p>

                    <ul>
                        <li>
                            An iron-based, non-calcium tablet<sup>1</sup>
                        </li>
                        <li>
                            Film-coated tablet that is swallowed, not chewed or
                            crushed<sup>1</sup>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            No discoloration of the mouth when swallowed whole
                            <sup>1</sup>
                        </li>
                    </ul>
                </div>
                <hr />
            </div>
        </section>

        <section id="guidelines" className="col-10 center">
            <div className="flex-row">
                <div>
                    <h2 className="hpp">
                        Dosing guidelines<sup>1</sup>
                    </h2>
                    <div className="media-cards-wrap hpp">
                        <ImgMediaCard
                            siteAudience="hpp"
                            delay="100"
                            imgalt="Artistic representation icon of a fork, knife and dish"
                            imgsrc={iconMeals}
                            content={
                                <>
                                    AURYXIA is taken
                                    <br />
                                    with meals
                                </>
                            }
                        />
                        <ImgMediaCard
                            siteAudience="hpp"
                            delay="500"
                            imgalt="Artistic representation of two capsules and the number three"
                            imgsrc={iconTablets}
                            content={
                                <>
                                    Starting dose{" "}
                                    <strong>
                                        2&nbsp;tablets <br />
                                        3&nbsp;times/day
                                    </strong>
                                </>
                            }
                        />
                        <ImgMediaCard
                            siteAudience="hpp"
                            delay="700"
                            imgalt="Artistic representation of a calendar"
                            imgsrc={iconCalendar}
                            content={
                                <>
                                    Adjust dose every week (or longer){" "}
                                    <strong>as&nbsp;needed</strong>
                                </>
                            }
                        />
                        <ImgMediaCard
                            siteAudience="hpp"
                            delay="900"
                            imgalt="Artistic representation of a circle with the number twelve in the middle"
                            imgsrc={iconMaxDose}
                            content={
                                <>
                                    Maximum dose
                                    <br />
                                    <strong>12 tablets daily</strong>
                                </>
                            }
                        />
                    </div>
                </div>
            </div>
        </section>
        <section id="titration" className="flood-hp col-10 center">
            <div className="flex-row">
                <div
                    id="titration-guidelines"
                    data-sal="zoom-in"
                    data-sal-duration="600"
                    data-sal-delay="200"
                    data-sal-ease="ease-out-expo"
                >
                    <h2 className="hpp">Titration guidelines</h2>

                    <div>
                        <span className="icon-wrap">
                            <img
                                src={iconTitrationPills}
                                alt="Artistic representation of two capsules with the plus and minus signs next to them"
                            />
                        </span>
                        <p>
                            AURYXIA can be titrated in increments or decrements
                            of 1 to 2 tablets per day to maintain serum
                            phosphorus target levels<sup>1</sup>
                        </p>
                    </div>

                    <div>
                        <span className="icon-wrap">
                            <img
                                src={iconTitrationCalendar}
                                alt="Artistic representation of a calendar"
                            />
                        </span>
                        <p>
                            Titrate AURYXIA at 1-week or longer intervals
                            <sup>1</sup>
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section id="drug-interactions" className="col-10 center">
            <div className="flex-row">
                <div id="drug-interactions-callout">
                    <h2 className="hpp">
                        Oral drugs that cannot be taken at the same time as
                        AURYXIA<sup>1</sup>
                    </h2>
                    <ul>
                        <li>
                            Doxycycline&#x2014;Take at least{" "}
                            <strong>1 hour before</strong> AURYXIA
                        </li>
                        <li>
                            Ciprofloxacin&#x2014;Take at least{" "}
                            <strong>2 hours before or after</strong> AURYXIA
                        </li>
                        <li>
                            For oral medications where a reduction in
                            bioavailability would have a clinically significant
                            effect, consider separation of timing of
                            administration with AURYXIA
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <div className="single_card">
            <EopCalloutCards
                siteAudience="hpp"
                callOneClass={"akebia-cares"}
                callOneLink={"https://www.akebiacareshcp.com/"}
                callOneContent={
                    <>
                        <img src={akbcares} alt="AkebiaCares" height="81" />
                        <h3>
                            Your partner in helping patients access the
                            medication they need
                        </h3>
                        <p>
                            AkebiaCares supports your patients with information
                            and resources that can help people start and stay on
                            AURYXIA.
                        </p>
                        <button className="cta akb">
                            <span>FIND SUPPORT OPTIONS</span>
                        </button>
                    </>
                }
            />
        </div>
    </HPPLayout>
)

export default DosingPage
