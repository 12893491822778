import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import IDALayout from "../../../components/layouts/layout-ida"
import Seo from "../../../components/seo"

import Chart1Lg from "../../../images/ida/pharmacodynamics/Chart1-LG@2x.png"
import Chart1Sm from "../../../images/ida/pharmacodynamics/Chart1-SM@2x.png"

import EopCalloutCards from "../../../components/shared/generic/eop-callout-cards"
import akbcares from "../../../images/AkebiaCares-logo.png"
import CalloutIcon1 from "../../../images/icons/icon-callout-ida-efficacy.png"

const IdaPharmacodynamics = ({ location, data }) => (
    <IDALayout
        location={location}
        locationHostName={location.hostname}
        jobCode="PP-AUR-US-1408 (v5.0)"
        jobCodeDate="11/24"
        references={[
            <>
                Fishbane S, Block GA, Loram L, et al. Effects of ferric citrate
                in patients with nondialysis-dependent CKD and iron deficiency
                anemia. <em>J Am Soc Nephrol.</em> 2017;28(6):1851-1858.
            </>,
            <>
                National Kidney Foundation. K/DOQI clinical practice guidelines
                for bone metabolism and disease in chronic kidney disease.{" "}
                <em>Am J Kidney Dis.</em> 2003;42(4 Suppl 3):S1-S201.
            </>,
            <>
                Supplement to: Fishbane S, Block GA, Loram L, et al. Effects of
                ferric citrate in patients with nondialysis-dependent CKD and
                iron deficiency anemia. <em>J Am Soc Nephrol.</em>{" "}
                2017;28(6):1851-1858.
            </>,
            <>
                AURYXIA<sup>®</sup> [Package Insert]. Cambridge, MA: Akebia
                Therapeutics, Inc.; 2021.
            </>,
            <>Data on File 16, Akebia Therapeutics, Inc.</>,
            <>Data on File 14, Akebia Therapeutics, Inc.</>,
        ]}
    >
        <Helmet>
            <html lang="en" />
            <link
                rel="canonical"
                href="https://www.auryxiahcp.com/iron-deficiency-anemia/safety/pharmacodynamics"
            />
            <body id="ida-hcp-pharmaco" className="ida" />
        </Helmet>

        <Seo
            title="Pharmacodynamics"
            description="Discover the pharmacodynamics for AURYXIA. See Prescribing Information and Full Safety Information."
        />

        <section id="hero" className="center col-8">
            <div className="flex-row">
                <div>
                    <h1>Safety: Pharmacodynamics in&nbsp;AURYXIA </h1>

                    <h2 className="ida">
                        AURYXIA has been shown to have an impact on
                        serum&nbsp;phosphorus<sup>1</sup>
                    </h2>
                </div>
            </div>
        </section>

        <section id="chart1" className="flood-ida col-10">
            <div
                className="flex-row"
                data-sal="slide-up"
                data-sal-duration="600"
                data-sal-delay="200"
                data-sal-ease="ease-out-expo"
            >
                <div>
                    <h3 className="ida">
                        Mean serum phosphorus decreased from baseline in
                        patients with AURYXIA<sup>1</sup>
                    </h3>

                    <figure>
                        <figcaption>
                            <p className="circle">AURYXIA (n=117)</p>
                        </figcaption>

                        <img
                            src={Chart1Lg}
                            className="large-only"
                            alt="Mean serum phosphorus decreased from baseline in patients with AURYXIA"
                        />
                        <img
                            src={Chart1Sm}
                            className="small-only"
                            alt="Mean serum phosphorus decreased from baseline in patients with AURYXIA"
                        />
                    </figure>
                </div>
            </div>
        </section>

        <section id="phosphorus" className="col-10">
            <div
                data-sal="slide-up"
                data-sal-duration="600"
                data-sal-delay="200"
                data-sal-ease="ease-out-expo"
            >
                <div>
                    <h3 className="ida">PHARMACODYNAMICS: PHOSPHORUS RANGE</h3>
                    <ul>
                        <li>
                            During the 16-week randomized period: Incidence of
                            hypophosphatemia, reported as a treatment-related
                            adverse event, occurred in 1% of patients treated
                            with AURYXIA (1 patient) and 2% of patients treated
                            with placebo (2 patients)<sup>3</sup>
                        </li>
                    </ul>
                </div>
            </div>
        </section>

        <EopCalloutCards
            siteAudience="ida"
            callOneClass={"highlight"}
            callOneLink={"/iron-deficiency-anemia/efficacy/"}
            callOneContent={
                <>
                    <img src={CalloutIcon1} alt="" width="98" height="98" />
                    <h3>See what AURYXIA can do for your patients</h3>
                    <p>
                        AURYXIA increased Hb and iron parameters (TSAT and
                        ferritin) without IV iron or ESA use in a 16-week
                        pivotal trial.<sup>1,4-6</sup>
                    </p>
                    <button className="cta ida">
                        <span>EXAMINE EFFICACY</span>
                    </button>
                    <p className="footnote center">
                        IV iron use and ESA use were not allowed per trial
                        design and exclusion criteria.<sup>1</sup>
                    </p>
                </>
            }
            callTwoClass={"akebia-cares"}
            callTwoLink={"https://www.akebiacareshcp.com/"}
            callTwoContent={
                <>
                    <img src={akbcares} alt="AkebiaCares" />
                    <h3>
                        Your partner in helping patients access the medication
                        they need
                    </h3>
                    <p>
                        AkebiaCares supports your patients with information and
                        resources that can help people start and stay on
                        AURYXIA.
                    </p>
                    <button className="cta akb">
                        <span>FIND SUPPORT OPTIONS</span>
                    </button>
                </>
            }
        />

        <section id="interested-slab" className="col-6 center">
            <div
                className="flex-row"
                data-sal="slide-up"
                data-sal-duration="600"
                data-sal-delay="200"
                data-sal-ease="ease-out-expo"
            >
                <div>
                    <h3>You may also be interested&nbsp;in:</h3>
                    <p>
                        <Link
                            to="/iron-deficiency-anemia/safety/adverse-reactions/"
                            className="gradient-link ida"
                        >
                            ADVERSE REACTIONS
                        </Link>
                    </p>
                </div>
            </div>
        </section>

        <section id="trial-design" className="flood-ida col-8">
            <div calss="flex-row">
                <div>
                    <p>
                        <strong>
                            Trial design<sup>5</sup>
                        </strong>
                    </p>

                    <p>
                        In a 24-week study consisting of a 16-week, randomized,
                        double-blind, placebo-controlled efficacy period
                        followed by an 8-week, open-label safety extension
                        period, this trial evaluated the efficacy and safety of
                        AURYXIA for the treatment of iron deficiency anemia in
                        adult patients with CKD not on dialysis. Patients who
                        were intolerant of or have had an inadequate therapeutic
                        response to oral iron supplements, with hemoglobin
                        &#x2265;9.0 g/dL and &#x2264;11.5 g/dL, serum ferritin
                        &#x2264;200 ng/mL, and TSAT &#x2264;25% were enrolled.
                        Patients were randomized to treatment with either
                        AURYXIA (n=117) or placebo (n=117).
                    </p>

                    <p>
                        The primary endpoint was the proportion of patients
                        achieving a &#x2265;1.0 g/dL increase in hemoglobin at
                        any time point during the 16-week efficacy period. Use
                        of oral iron, IV iron, or ESAs was not permitted at any
                        time during the trial.{" "}
                    </p>

                    <p className="footnote">
                        CKD=chronic kidney disease; KDOQI=Kidney Disease
                        Outcomes Quality Initiative.{" "}
                    </p>
                </div>
            </div>
        </section>
    </IDALayout>
)

export default IdaPharmacodynamics
